import { lazy, Suspense } from "react";
//import './App.css';

import { Provider } from "react-redux";
import { store, persistedStore } from "./store";
import { PersistGate } from "redux-persist/es/integration/react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import AuthIsLoaded from "./FbAuth/AuthIsLoaded";
import SplashScreen from "./components/splashscreen/SplashScreen";

const PageNotFound = lazy(() => import("./pages/404"));
const Childcarer = lazy(() => import("./pages/Childcarer"));
const About = lazy(() => import("./pages/About"));
const Signin = lazy(() => import("./pages/Signin"));
const Register = lazy(() => import("./pages/Register"));
const Job = lazy(() => import("./pages/Job"));
const RolesDetails = lazy(() => import("./pages/RolesDetails"));
const Acc = lazy(() => import("./pages/acc"));
const Profile = lazy(() => import("./pages/acc/Profile"));
const Home = lazy(() => import("./pages/home"));
const Parents = lazy(() => import("./pages/Parents"));
const Hub = lazy(() => import("./pages/Hub"));

const PrivateRoute = lazy(() => import("./routing"));
const Featured = lazy(() => import("./pages/acc/Featured"));
const Settings = lazy(() => import("./pages/acc/Settings"));
const BasicInfo = lazy(() => import("./pages/acc/profile_edit/BasicInfo"));
const EditRoles = lazy(() => import("./pages/acc/profile_edit/EditRoles"));
const Uploads = lazy(() => import("./pages/acc/profile_edit/Uploads"));
const PersonalInfo = lazy(() =>
  import("./pages/acc/profile_edit/PersonalInfo")
);
const PersonalDetails = lazy(() =>
  import("./pages/acc/profile_edit/PersonalDetails")
);
const Pay = lazy(() => import("./pages/acc/Pay"));
const CreateJobs = lazy(() => import("./pages/acc/CreateJobs"));
const CreateJob = lazy(() => import("./pages/CreateJob"));
const Auth = lazy(() => import("./pages/Auth"));
const AuthLink = lazy(() => import("./pages/AuthLink"));

const ParentAccRoute = lazy(() => import("./routing/ParentAccRoute"));
const AdminCareGiverRoute = lazy(() => import("./routing/AdminCareGiverRoute"));
const Referrals = lazy(() => import("./pages/acc/Referrals"));
const Contact = lazy(() => import("./pages/Contact"));
const ViewPosts = lazy(() => import("./pages/ViewPosts"));
const Apanel = lazy(() => import("./pages/acc/Apanel"));
const Reports = lazy(() => import("./pages/Reports"));
const Course = lazy(() => import("./pages/Courses"));
const Terms = lazy(() => import("./pages/Terms"));
const TermsZed = lazy(() => import("./pages/TermsZed"));
const Users = lazy(() => import("./pages/Users"));
const Menu = lazy(() => import("./pages/Menu"));
const Cart = lazy(() => import("./pages/Cart"));
const Orders = lazy(() => import("./pages/Orders"));
// const Courses = lazy(() => import("./pages/acc/Courses"));
const PersonalityTests = lazy(() => import("./pages/acc/PersonalityTests"));
const MyOrders = lazy(() => import("./pages/acc/MyOrders"));
const MyPosts = lazy(() => import("./pages/acc/MyPosts"));
const CartOrders = lazy(() => import("./pages/acc/CartOrders"));
const JobNest = lazy(() => import("./pages/acc/JobNest"));
const RegisterSocial = lazy(() => import("./pages/RegisterSocial"));

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistedStore} loading={null}>
        <Router>
          <AuthIsLoaded>
            <Switch>
              <Suspense fallback={<SplashScreen />}>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/find_job/:id">
                  <Childcarer />
                </Route>

                <Route exact path="/policy">
                  <Terms />
                </Route>
                <Route exact path="/startupZambia/policy">
                  <TermsZed />
                </Route>
                <Route exact path="/find_childcarer/:id">
                  <Parents />
                </Route>
                <Route exact path="/advice_hub">
                  <Hub />
                </Route>
                <Route exact path="/courses">
                  <Course />
                </Route>
                <Route exact path="/aboutus">
                  <About />
                </Route>
                <Route exact path="/contactus">
                  <Contact />
                </Route>
                <Route exact path="/login/:id">
                  <Signin />
                </Route>
                <Route exact path="/register/:id">
                  <Register />
                </Route>
                <Route exact path="/job/:id">
                  <Job />
                </Route>
                <Route exact path="/view_post/:id">
                  <ViewPosts />
                </Route>
                <Route exact path="/cv/:id">
                  <PersonalDetails />
                </Route>
                <Route exact path="/auth/:id">
                  <Auth />
                </Route>
                <Route exact path="/register_social/:id">
                  <RegisterSocial />
                </Route>
                <Route exact path="/menu">
                  <Menu />
                </Route>

                <Route exact path="/email_verification/:id">
                  <AuthLink />
                </Route>
                <Route exact path="/roles_and_duties/:role">
                  <RolesDetails />
                </Route>
                <Route exact path="/cart/:uid/:pid/:oid">
                  <Cart />
                </Route>
                <Route exact path="/order/:uid/:pid/:oid">
                  <Cart />
                </Route>
                <AdminCareGiverRoute exact path="/users">
                  <Users />
                </AdminCareGiverRoute>
                <AdminCareGiverRoute exact path="/admin">
                  <Apanel />
                </AdminCareGiverRoute>
                <AdminCareGiverRoute exact path="/reports/:id">
                  <Reports />
                </AdminCareGiverRoute>
                <AdminCareGiverRoute exact path="/orders">
                  <Orders />
                </AdminCareGiverRoute>
                <ParentAccRoute
                  exact
                  path="/my_account/parent/overview/user/:return">
                  <Acc />
                </ParentAccRoute>
                <ParentAccRoute exact path="/createjob">
                  <CreateJob />
                </ParentAccRoute>
                <ParentAccRoute
                  exact
                  path="/my_account/parent/pay/user/:return">
                  <Pay />
                </ParentAccRoute>
                <ParentAccRoute
                  exact
                  path="/my_account/parent/jobs_profiles/manage_jobs/:return">
                  <CreateJobs />
                </ParentAccRoute>
                <ParentAccRoute
                  exact
                  path="/my_account/parent/jobs_profiles/contact_history/:return">
                  <JobNest />
                </ParentAccRoute>
                <ParentAccRoute
                  exact
                  path="/my_account/parent/jobs_profiles/recieved_applications/:return">
                  <JobNest />
                </ParentAccRoute>
                {/* <ParentAccRoute
                  exact
                  path="/my_account/parent/courses/user/:return">
                  <Courses />
                </ParentAccRoute> */}
                <ParentAccRoute
                  exact
                  path="/my_account/parent/carts_orders/orders/:return">
                  <CartOrders />
                </ParentAccRoute>
                <ParentAccRoute
                  exact
                  path="/my_account/parent/referrals/user/:return">
                  <Referrals />
                </ParentAccRoute>
                <ParentAccRoute
                  exact
                  path="/my_account/parent/carts_orders/carts/:return">
                  <CartOrders />
                </ParentAccRoute>
                <ParentAccRoute
                  exact
                  path="/my_account/parent/posts/user/:return">
                  <MyPosts />
                </ParentAccRoute>
                <ParentAccRoute
                  exact
                  path="/my_account/parent/settings/user/:return">
                  <Settings />
                </ParentAccRoute>

                <PrivateRoute
                  exact
                  path="/my_account/caregiver/overview/user/:return">
                  <Acc />
                </PrivateRoute>

                <PrivateRoute
                  exact
                  path="/my_account/caregiver/my_profile/user/:return">
                  <Profile />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/my_account/caregiver/pay/user/:return">
                  <Pay />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/my_account/caregiver/my_profile/basic_information_edit/:return">
                  <PersonalInfo />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/my_account/caregiver/my_profile/roles_edit/:return">
                  <EditRoles />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/my_account/caregiver/my_profile/uploads_edit/:return">
                  <Uploads />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/my_account/caregiver/my_profile/personal_description_edit/:return">
                  <BasicInfo />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/my_account/caregiver/featured_nanny/user/:return">
                  <Featured />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/my_account/caregiver/apply_offers/recieved_job_offers/:return">
                  <JobNest />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/my_account/caregiver/apply_offers/my_applications/:return">
                  <JobNest />
                </PrivateRoute>
                {/* <PrivateRoute
                  exact
                  path="/my_account/caregiver/courses/user/:return">
                  <Courses />
                </PrivateRoute> */}
                <PrivateRoute
                  exact
                  path="/my_account/caregiver/personality_tests/user/:return">
                  <PersonalityTests />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/my_account/caregiver/orders/user/:return">
                  <MyOrders />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/my_account/caregiver/referrals/user/:return">
                  <Referrals />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/my_account/caregiver/posts/user/:return">
                  <MyPosts />
                </PrivateRoute>

                <PrivateRoute
                  exact
                  path="/my_account/caregiver/settings/user/:return">
                  <Settings />
                </PrivateRoute>
                <Route exact path="/404">
                  <PageNotFound />
                </Route>
              </Suspense>
              <Redirect to="/404" />
            </Switch>
          </AuthIsLoaded>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
